import React, { useEffect, useState } from "react";

import {
  Dialog,
  DialogHeader,
  DialogBody,
  Button,
} from "@material-tailwind/react";

import { IoEyeSharp } from "react-icons/io5";
import { HiPencilAlt } from "react-icons/hi";
import { FaTrash, FaFileDownload } from "react-icons/fa";
import { MdPictureAsPdf } from "react-icons/md";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import FTLModale from "../Modales/FTL_modale";
import {
  deleteOneCompanyFeesApi,
  updateCompanyFeesPayStatusApi,
} from "api/companyFees";

import API_URL from "config";
import { pdfOneCompanyFeeGeneratorApi } from "api/pdf";

function FTL({
  tableData,
  refreshTableData,
  accessList,
  showToast,
  selectedFilters,
}) {
  const [showCompanyFeesModaleFtl, setShowCompanyFeesModaleFtl] =
    useState(false);
  const [filtredTableData, setFiltredTableData] = useState([]);

  const [selectedData, setSelectedData] = useState({});
  const [selectedTypeOfData, setSelectedTypeOfData] = useState("");

  useEffect(() => {
    getAllTableData();
  }, [tableData]);

  const handleUpdatedPayStatus = async (status, id) => {
    try {
      const selectedStatus =
        status == "N" || status == "notPayed" ? "payed" : "notPayed";

      try {
        withReactContent(Swal)
          .fire({
            icon: "warning",
            confirmButtonText: `${
              selectedStatus == "payed" ? "Payer ?" : "unpayer ?"
            }`,
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              try {
                await updateCompanyFeesPayStatusApi(selectedStatus, id);
                showToast("success", "Modifiée");
              } catch (err) {
                console.log(err);
              }
            }
          });
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      showToast("error", err?.response?.data?.error);
    }
  };

  const getAllTableData = () => {
    try {
      setFiltredTableData(tableData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDownloadBon = (file) => {
    try {
      window.open(`${API_URL}/${file}`, "_blank");
    } catch (err) {}
  };

  const handleDownloadPdf = async (data) => {
    try {
      const response = await pdfOneCompanyFeeGeneratorApi(data._id);
      window.open(`${response}`, "_blank");
    } catch (err) {}
  };

  const handleCompanyFeeEditeModale = (type, data) => {
    try {
      setShowCompanyFeesModaleFtl(!showCompanyFeesModaleFtl);

      setSelectedData(data);
      setSelectedTypeOfData(type);
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowToast = (type, message) => {
    showToast(type, message);
  };

  const handleDeleteFee = (el) => {
    try {
      withReactContent(Swal)
        .fire({
          icon: "warning",
          title: "Supprimer ?",
          confirmButtonText: "Supprimer",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              Swal.fire("Supprimée", "", "success");

              await deleteOneCompanyFeesApi(el._id);
              refreshTableData("success", "Supprimée");
            } catch (err) {
              console.log(err);
            }
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="mt-8 overflow-x-scroll">
      <Dialog
        size="xl"
        open={showCompanyFeesModaleFtl}
        handler={handleCompanyFeeEditeModale}
      >
        <DialogHeader>
          <p className="w-full text-center">Frais de transport local</p>
        </DialogHeader>
        <DialogBody>
          <FTLModale
            closePopup={handleCompanyFeeEditeModale}
            actionType={selectedTypeOfData}
            selectedData={selectedData}
            showToast={handleShowToast}
          />
        </DialogBody>
      </Dialog>

      <div
        className="mt-8 overflow-x-scroll"
        style={{ height: "50vh", overflowY: "scroll" }}
      >
        <table className="w-full">
          <thead>
            <tr>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Date</p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Type</p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Départ</p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Arrivée</p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">
                  Responsable
                </p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">
                  Etat (Justifié)
                </p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">
                  Etat (Approuvée)
                </p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Montant</p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Bon / PDF</p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">
                  Validation
                </p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">
                  Validation (D.G)
                </p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">
                  Payée / Impayée
                </p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Action</p>
              </th>
            </tr>
          </thead>

          <tbody>
            {filtredTableData.map((el) => (
              <tr>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {el?.date?.split("T")[0]}
                  </p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    Frais de transport local
                  </p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {el?.fromCity?.Name || el?.startedPoint}
                  </p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {el?.toCity?.Name || el?.endPoint}
                  </p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {el?.responsable?.Name}
                  </p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {el?.isJustify ? "Justifié" : "Non Justifié"}
                  </p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {el?.isAproved ? "Approuvée" : "Non Approuvée"}
                  </p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {parseFloat(el?.montant).toFixed(2)}
                  </p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {accessList.Read && (
                      <div className="justify-left items-left flex">
                        <FaFileDownload
                          size={30}
                          color="black"
                          className="cursor-pointer"
                          onClick={() => handleDownloadBon(el.bonFile)}
                        />
                        <MdPictureAsPdf
                          size={30}
                          color="black"
                          className="ml-4 cursor-pointer"
                          onClick={() => handleDownloadPdf(el)}
                        />
                      </div>
                    )}
                  </p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    <Button
                      variant="text"
                      color="gray"
                      className="dark:bg-white"
                    >
                      <span>
                        {el.tlValidationStatus === "N"
                          ? "En cours"
                          : el.tlValidationStatus === "V"
                          ? "Validée"
                          : "Annulée"}
                      </span>
                    </Button>
                  </p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    <Button
                      variant="text"
                      color="gray"
                      className="dark:bg-white"
                    >
                      <span>
                        {el.dgValidationStatus === "N"
                          ? "En cours"
                          : el.dgValidationStatus === "V"
                          ? "Validée"
                          : "Annulée"}
                      </span>
                    </Button>
                  </p>
                </td>

                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  {!el.payeStatus ||
                  el.payeStatus === "" ||
                  el.payeStatus === "N" ? (
                    <Button
                      color="amber"
                      className="text-white"
                      onClick={() => handleUpdatedPayStatus("N", el._id)}
                    >
                      En course
                    </Button>
                  ) : el.payeStatus === "payed" ? (
                    <Button
                      color="#236571"
                      className="text-white"
                      onClick={() => handleUpdatedPayStatus("payed", el._id)}
                    >
                      Payée
                    </Button>
                  ) : el.payeStatus === "notPayed" ? (
                    <Button
                      color="#cf503f"
                      className="text-white"
                      onClick={() => handleUpdatedPayStatus("notPayed", el._id)}
                    >
                      Non payée
                    </Button>
                  ) : (
                    <></>
                  )}
                </td>

                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    <div className="flex">
                      {accessList.Read && (
                        <IoEyeSharp
                          size={20}
                          color="gray"
                          className="cursor-pointer"
                          onClick={() =>
                            handleCompanyFeeEditeModale("show", el)
                          }
                        />
                      )}
                      {accessList.Update && (
                        <HiPencilAlt
                          size={20}
                          color="#236571"
                          className="ml-4 mr-4 cursor-pointer"
                          onClick={() =>
                            handleCompanyFeeEditeModale("update", el)
                          }
                        />
                      )}
                      {accessList.Delete && (
                        <FaTrash
                          size={20}
                          color="#cf503f"
                          className="cursor-pointer"
                          onClick={() => handleDeleteFee(el)}
                        />
                      )}
                    </div>
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default FTL;
