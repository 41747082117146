import { Button } from '@material-tailwind/react';
import React from 'react'

function StockEnterEditeForm({ closePopup }) {
    const handleCancelOp = () => {
        try {
          closePopup("Close");
        } catch (err) {}
      };

      const handleEditeStock = () => {
        try {
          alert('Stock updated !!!')
        }
        catch (err) {
          
        }
      }

  return (
    <form>
      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Date d'éxpiration
          </label>
          <input
            type="date"
            id=""
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Date
          </label>
          <input
            type="date"
            id=""
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Dépot
          </label>
          <select
              label="select Version"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              defaultValue="Tout"
            >
              <option disabled value="Tout">
                Tout
              </option>
              <option>Dépot A</option>
              <option>Dépot B</option>
              <option>Dépot C</option>
            </select>
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Catégorie 
          </label>
          <select
              label="select Version"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              defaultValue="Tout"
            >
              <option disabled value="Tout">
                Tout
              </option>
              <option>Catégorie A</option>
              <option>Catégorie B</option>
              <option>Catégorie C</option>
            </select>
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Numéro de lot
          </label>
          <input
            type="text"
            id=""
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Quantité :
          </label>
          <input
            type="number"
            id=""
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Numéro de lot :
          </label>
          <input
            type="text"
            id=""
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
          />
        </div>
      </div>

      <div className="mt-5">
          <Button
            variant="gradient"
            color="#236571"
            className="mr-1"
            onClick={() => handleEditeStock()}
          >
            <span>Modifier</span>
          </Button>
          <Button
            variant="text"
            color="#cf503f"
            className="mr-1"
            onClick={handleCancelOp}
          >
            <span>Annuler</span>
          </Button>
        </div>


    </form>
  )
}

export default StockEnterEditeForm