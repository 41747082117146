import React, { useEffect, useState } from "react";
import { Button } from "@material-tailwind/react";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { getAllTcpsApi } from "api/filtersData";
import { updateIneUpdatedEssayReportsApi } from "api/essay";

function OneEssayEditeForm({ selectedOneEssayEditeForm, handleCancelForm, showToast }) {
  const [tcpData, setTcpData] = useState([]);
  const [essayFormData, setEssayFormData] = useState({
    updatedAt: "",
    fromUser: "",
    description: "",
  });

  useEffect(() => {
    initialFormData();
  }, [selectedOneEssayEditeForm._id]);

  const initialFormData = async () => {
    try {
      const tcpData = await getAllTcpsApi();
      setTcpData(tcpData);

      setEssayFormData({
        createdAt: selectedOneEssayEditeForm.updatedCreatedDate ? `${selectedOneEssayEditeForm.updatedCreatedDate.slice(0, 19)}` : `${selectedOneEssayEditeForm.createdAt.slice(0, 19)}`,
        fromUser: selectedOneEssayEditeForm.fromUser._id,
        description: selectedOneEssayEditeForm.description,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEssayFormData({ ...essayFormData, [name]: value });
  };

  const handleUpdateOneEssayData = async () => {
    try {
      withReactContent(Swal)
      .fire({
        icon: "warning",
        title: "Voulez-vous vraiment modifier ?",
        confirmButtonText: "Modifier",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            await updateIneUpdatedEssayReportsApi(selectedOneEssayEditeForm._id, essayFormData)

            // handleCancelForm()
            // Swal.fire("Modifiée", "", "success");
            // showToast("success", "Modifiée")
          } catch (err) {
            console.log(err)
            Swal.fire("Cesser une lien correct", "", "error");
          } finally {
          }
        }
      })
    } catch (err) {
      console.log(err);
    }
  };

  const handleCloseModal = () => {
    try {
      handleCancelForm()
    }
    catch (err) {
      console.log(err)
    }
  }

  return (
    <form
      style={{
        overflow: "scroll",
        height: "80vh",
      }}
    >
      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Date de création
          </label>
          <input
            type="datetime-local"
            id=""
            placeholder="Date de création"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            value={essayFormData?.createdAt}
            name="createdAt"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            TCP
          </label>
          <select
            label="select Version"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            value={essayFormData?.fromUser}
            name="fromUser"
            onChange={handleInputChange}
          >
            <option disabled value="">
              Sélectionner le TCP
            </option>
            {tcpData.map((el) => (
              <option value={el._id}>{el.Name}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Description
          </label>
          <input
            type="text"
            id=""
            placeholder="Description"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            value={essayFormData?.description}
            name="description"
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="mt-5">
        <Button
          variant="gradient"
          color="#236571"
          onClick={() => handleUpdateOneEssayData()}
          className="mr-1"
        >
          <span>Modifier</span>
        </Button>
        <Button
          variant="text"
          color="#cf503f"
          onClick={() => handleCloseModal()}
          className="mr-1"
        >
          <span>Annuler</span>
        </Button>
      </div>
    </form>
  );
}

export default OneEssayEditeForm;
