import React, { useEffect, useState } from "react";
import Card from "components/card";
import { Button, Spinner, IconButton } from "@material-tailwind/react";

import { IoEyeSharp } from "react-icons/io5";
import { HiPencilAlt } from "react-icons/hi";
import { FaTrash } from "react-icons/fa";
import { MdPictureAsPdf } from "react-icons/md";

import {
  IoMdArrowDropleftCircle,
  IoMdArrowDroprightCircle,
} from "react-icons/io";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { Dialog, DialogHeader, DialogBody } from "@material-tailwind/react";

import OperationForm from "./Operation_form";
import OperationProducts from "./Operation_products";
import OperationReports from "./Operation_reports";
import { getAllTcpsApi } from "api/filtersData";
import { getAllOpReportsListApi } from "api/operations";
import { updateReportDataApi } from "api/gestionStock";

function ListOfOperations({ accessList }) {
  const [showOperationForm, setOperationForm] = useState(false);
  const [showOperationReports, setOperationReports] = useState(false);
  const [showOperationProducts, setOperationProducts] = useState(false);

  const [selectedOperation, setSelectedOperation] = useState({})

  const [listOfOperations, setListOfOperations] = useState([]);
  const [listOfAllTcp, setListOfTcp] = useState([])

  const [inputFilterValue, setInputFilterValue] = useState({
    TCP: "",
    MP: "Devis",
    currentYear: new Date().getUTCFullYear()
  });

  const [isLoading, setIsLoading] = useState(false);
  const [selectedOp, setSelectedOp] = useState({})

  // paginations
  const [active, setActive] = useState(1);
  const [currentPagination, setCurrentPagination] = useState(1);

  const [numberOfPages, setNumberOfPages] = useState(0);
  const [numberOfPaginations, setNumberOfPaginations] = useState(0);
  const [numberOfReports, setNumberOfReports] = useState(0);

  useEffect(() => {
    getListOfAllTcp()
  }, [])

  useEffect(() => {
    getInitialData(
      inputFilterValue.MP,
      inputFilterValue.TCP,
      "",
      inputFilterValue.currentYear,
      "",
      "",
      "",
      active
    );
  }, [inputFilterValue]);

  const handleFiltersInputChange = async (e) => {
    try {
      setIsLoading(true)

      const { name, value } = e.target;
      setInputFilterValue((prevInputFilterValue) => ({
        ...prevInputFilterValue,
        [name]: value,
      }));

      await getInitialData(
        name === "MP" ? value : inputFilterValue.MP,
        name === "TCP" ? value : inputFilterValue.TCP,
        "",
        name === "currentYear" ? value : inputFilterValue.currentYear,
        "",
        "",
        "",
        active
      );
    }
    catch (err){
      console.log(err)
    }
    finally {
      setActive(1)
      setCurrentPagination(1)

      setIsLoading(false)
    }
  };

  const getListOfAllTcp = async () => {
    try {
      const response = await getAllTcpsApi()
      setListOfTcp(response)
    }
    catch (err) {
      console.log(err)
    }
  }

  const getInitialData = async (MP, TCP, OP, currentYear, RBC, RBL, RF, Page) => {
    try {
      setIsLoading(true);
      const response = await getAllOpReportsListApi(MP, TCP, OP, currentYear, RBC, RBL, RF, Page);

      setListOfOperations(response.data);
      setNumberOfPages(response.pagination.numberOfPages);
      setNumberOfPaginations(response.pagination.numberOfPaginations);
      setNumberOfReports(response.pagination.numberOfReports);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteReport = (ID) => {
    try {
      withReactContent(Swal)
        .fire({
          icon: "warning",
          title: "Supprimer ?",
          confirmButtonText: "Supprimer",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const updatedObject = {
              isArchieved: true
            };
            await updateReportDataApi(ID, updatedObject)

            getInitialData(
              inputFilterValue.MP,
              inputFilterValue.TCP,
              "",
              inputFilterValue.currentYear,
              "",
              "",
              "",
              active
            );

            Swal.fire("Supprimée", "", "success");
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleReportModal = (data) => {
    try {
      setSelectedOperation(data)
      setOperationReports(!showOperationReports);
    } catch (err) {
      console.log(err);
    }
  };
  const handleReportFormModal = (id) => {
    try {
      setOperationForm(!showOperationForm);
    } catch (err) {
      console.log(err);
    }
  };
  const handleReportProductsModal = (data) => {
    try {
      setOperationProducts(!showOperationProducts);
      setSelectedOp(data)
    } catch (err) {
      console.log(err);
    }
  };

    // pagination
    const getItemProps = (index) => ({
      variant: active === index ? "filled" : "text",
      color: "gray",
      onClick: () => {
        getInitialData(
          inputFilterValue.MP,
          inputFilterValue.TCP,
          "",
          inputFilterValue.currentYear,
          "",
          "",
          "",
          index
        );
        setActive(index);
      },
    });
  
    const next = () => {
      if (currentPagination === numberOfPaginations) return;
      setCurrentPagination(currentPagination + 1);
    };
  
    const prev = () => {
      if (currentPagination === 1) return;
      setCurrentPagination(currentPagination - 1);
    };
    // pagination

  return (
    <Card extra={"w-full pb-10 p-4 h-full"}>
      <Dialog
        size="xl"
        open={showOperationForm}
        handler={handleReportFormModal}
      >
        <DialogHeader>
          <p className="w-full text-center">Modifier un rapport</p>
        </DialogHeader>
        <DialogBody>
          <OperationForm
            closePopup={handleReportFormModal}
            operationFormId=""
          />
        </DialogBody>
      </Dialog>

      <Dialog size="xl" open={showOperationReports} handler={handleReportModal}>
        <DialogHeader>
          <p className="w-full text-center">Aperçu pdf details</p>
        </DialogHeader>
        <DialogBody>
          <OperationReports
            closePopup={handleReportModal}
            operationDetails={selectedOperation}
          />
        </DialogBody>
      </Dialog>

      <Dialog
        size="xl"
        open={showOperationProducts}
        handler={handleReportProductsModal}
      >
        <DialogHeader>
          <p className="w-full text-center">Aperçu</p>
        </DialogHeader>
        <DialogBody>
          <OperationProducts
            closePopup={handleReportProductsModal}
            selectedOp={selectedOp}
          />
        </DialogBody>
      </Dialog>

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Filtrer par TCP :
          </small>
          <div>
            <select
              label="select Version"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              defaultValue=""

              name="TCP"
              value={inputFilterValue.TCP}
              onChange={handleFiltersInputChange}
            >
              <option value="">Tout</option>
              {
                listOfAllTcp.map(el => (
                  <option value={el._id}>{el.Name}</option>
                ))
              }
            </select>
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Filtrer par opération :
          </small>
          <div>
            <select
              label="select Version"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              defaultValue="Devis"

              name="MP"
              value={inputFilterValue.MP}
              onChange={handleFiltersInputChange}
            >
              <option value="Devis">Devis</option>
              <option value="VisiteEssai">Visite d'essai</option>
              <option value="VisiteDeveloppement">Visite de développement</option>
              <option value="Autre">Autre</option>
            </select>
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Année :
          </small>
          <div>
            <input
              type="number"
              id=""
              placeholder="Tout"
              min="1900"
              max={new Date().getUTCFullYear()}
              step="1"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

              name="currentYear"
              value={inputFilterValue.currentYear}
              onChange={handleFiltersInputChange}
            />
          </div>
        </div>
      </div>
      {
        isLoading ?
        <div className="flex w-full items-center justify-center">
          <Spinner className="h-12 w-12" />
        </div> :
        <div>
          <div
            className="mt-8 overflow-x-scroll"
            style={{ height: "50vh", overflowY: "scroll" }}
          >
            <table className="w-full">
              <thead>
                <tr>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">Opération</p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">N°</p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">TCP</p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">Nom</p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">Produit</p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">Action</p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">Commentaire</p>
                  </th>
                </tr>
              </thead>

              <tbody>
                {
                  listOfOperations.map(el => (
                    <tr>
                      <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {el.ModeOperation}
                        </p>
                      </td>
                      <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {el.ModeOperation == "Devis" ? el.RBC : ""}
                        </p>
                      </td>
                      <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {el.for.Name}
                        </p>
                      </td>
                      <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {el.InfoGenerale.NomSociete}
                        </p>
                      </td>
                      <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {
                            accessList.Read &&
                            <IoEyeSharp
                              size={20}
                              color="gray"
                              className="cursor-pointer"
                              onClick={() => handleReportProductsModal(el)}
                            />
                          }
                        </p>
                      </td>
                      <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          <div className="flex">
                            {
                              accessList.Read &&
                              <MdPictureAsPdf
                                size={20}
                                color="gray"
                                className="cursor-pointer mr-4"
                                onClick={() => handleReportModal(el)}
                              />
                            }
                            {
                              accessList.Delete &&
                              <FaTrash
                                size={20}
                                color="#cf503f"
                                className="cursor-pointer"
                                onClick={() => handleDeleteReport(el._id)}
                              />
                            }
                          </div>
                        </p>
                      </td>
                      <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {el?.Comment}
                        </p>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>

          {
            <div className="mt-4 flex items-center gap-4">
            <Button
              variant="text"
              className="flex items-center gap-2"
              onClick={prev}
              disabled={currentPagination === 1}
            >
              <IoMdArrowDropleftCircle size={30} />
            </Button>
            <div className="flex items-center gap-2">
              {currentPagination !== numberOfPaginations
                ? // paginations row
                  Array.from({
                    length: numberOfPages > 5 ? 5 : numberOfPages,
                  }).map((el, index) => {
                    const pageNumber = index + 1 + (currentPagination - 1) * 10;
                    return (
                      <IconButton {...getItemProps(pageNumber)}>
                        {pageNumber}
                      </IconButton>
                    );
                  })
                : // last pagination row
                  Array.from({ length: numberOfPages % 10 }).map((el, index) => {
                    const pageNumber = index + 1 + (currentPagination - 1) * 5;
                    return (
                      <IconButton {...getItemProps(pageNumber)}>
                        {pageNumber}
                      </IconButton>
                    );
                  })}
            </div>
            <Button
              variant="text"
              className="flex items-center gap-2"
              onClick={next}
              disabled={
                currentPagination === numberOfPaginations ||
                numberOfPaginations === 0
              }
            >
              <IoMdArrowDroprightCircle size={30} />
            </Button>
            </div>
          }

        </div>
      }
    </Card>
  );
}

export default ListOfOperations;
