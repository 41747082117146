import React, { useEffect, useState } from "react";
import Card from "components/card";

import { IoEyeSharp } from "react-icons/io5";
import { HiPencilAlt } from "react-icons/hi";
import { FaTrash } from "react-icons/fa";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
  Dialog,
  DialogHeader,
  DialogBody,
  Button,
  Spinner,
} from "@material-tailwind/react";

import EditeRecouvry from "./ReportDetails";
import {
  getAllRecouvryApi,
  impayerApi,
  encaisserApi,
  undoImpayerApi,
  undoEncaisserApi,
  updateRecouvrementDate,
} from "api/recovry";
import { getOneReportDetailsApi } from "api/gestionStock";
import { pdfRecouvryGeneratorApi } from "api/pdf";

function RecouvryTable({ accessList }) {
  const [showEditeRecouvry, setShowEditeRecouvry] = useState(false);

  const [selectedRecouvry, setSelectedRecouvry] = useState(null);

  const [listOfRecouvry, setListOfRecouvry] = useState([]);

  const [selectedYear, setSelectedYear] = useState(new Date().getUTCFullYear());
  // const [selectedYear, setSelectedYear] = useState(2024);

  const [rangeOfYears, setRangeOfYears] = useState([]);

  const [inputFilterValue, setInputFilterValue] = useState({
    clientName: "",
    clientCode: "",
    typeOfOp: "",
    E_I: "",
    formDate: "",
    toDate: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getRangeOfYears(2022, new Date().getUTCFullYear());
  }, []);

  useEffect(() => {
    getInitialData(
      inputFilterValue.clientName,
      inputFilterValue.typeOfOp,
      inputFilterValue.E_I,
      inputFilterValue.formDate,
      inputFilterValue.toDate,
      selectedYear,
      inputFilterValue.clientCode
    );
  }, []); // inputFilterValue, selectedYear

  const getRangeOfYears = (startYear, endYear) => {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }

    setRangeOfYears(years);
  };

  const getInitialData = async (
    clientName,
    typeOfOp,
    E_I,
    formDate,
    toDate,
    currentYear,
    clientCode
  ) => {
    try {
      setIsLoading(true);
      const response = await getAllRecouvryApi(
        clientName,
        typeOfOp,
        E_I,
        formDate,
        toDate,
        currentYear,
        clientCode
      );
      setListOfRecouvry(response);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFiltersInputChange = (e) => {
    const { name, value } = e.target;
    setInputFilterValue({ ...inputFilterValue, [name]: value });
    // getInitialData(
    //   inputFilterValue.clientName,
    //   inputFilterValue.typeOfOp,
    //   inputFilterValue.E_I,
    //   inputFilterValue.formDate,
    //   inputFilterValue.toDate,
    //   selectedYear,
    //   inputFilterValue.clientCode
    // );
  };

  const handleUpdateData = () => {
    getInitialData(
      inputFilterValue.clientName,
      inputFilterValue.typeOfOp,
      inputFilterValue.E_I,
      inputFilterValue.formDate,
      inputFilterValue.toDate,
      selectedYear,
      inputFilterValue.clientCode
    );
  };

  const handleDeleteRecovry = async (reportID, type, index) => {
    try {
      withReactContent(Swal)
        .fire({
          icon: "warning",
          title: "Supprimer ?",
          confirmButtonText: "Supprimer",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            console.log(reportID);
            const reportData = await getOneReportDetailsApi(reportID);

            if (type === "Cheque") {
              reportData.Rapport.recouvrement[type].ChequeInfo =
                reportData.Rapport.recouvrement[type].ChequeInfo.filter(
                  (el) => el.key !== index
                );
            } else if (type === "Effet") {
              reportData.Rapport.recouvrement[type].EffetInfo =
                reportData.Rapport.recouvrement[type].EffetInfo.filter(
                  (el) => el.key !== index
                );
            } else {
              reportData.Rapport.recouvrement[type].montant = "";
            }

            await updateRecouvrementDate(reportID, reportData.Rapport);
            getInitialData(
              inputFilterValue.clientName,
              inputFilterValue.typeOfOp,
              inputFilterValue.E_I,
              inputFilterValue.formDate,
              inputFilterValue.toDate,
              selectedYear,
              inputFilterValue.clientCode
            );
            Swal.fire("Supprimée", "", "success");
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleRecovryModal = (type, id, index) => {
    try {
      setShowEditeRecouvry(!showEditeRecouvry);

      setSelectedRecouvry({
        id: id,
        type: type,
        index: index,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowRecovryReport = async (id, category) => {
    try {
      const response = await pdfRecouvryGeneratorApi(id, category);
      window.open(`${response}`, "_blank");
    } catch (err) {
      console.log(err);
    }
  };

  const handleImpayer = (ClientCode, rapportID, category, categoryKey) => {
    try {
      if (accessList.Update) {
        withReactContent(Swal)
          .fire({
            icon: "warning",
            title: "Impayer ?",
            confirmButtonText: "Impayer",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await impayerApi(ClientCode, rapportID, category, categoryKey);

              getInitialData(
                inputFilterValue.clientName,
                inputFilterValue.typeOfOp,
                inputFilterValue.E_I,
                inputFilterValue.formDate,
                inputFilterValue.toDate,
                selectedYear,
                inputFilterValue.clientCode
              );

              Swal.fire("Impayé", "", "success");
            }
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleEncaisser = async (
    ClientCode,
    rapportID,
    category,
    categoryKey
  ) => {
    try {
      if (accessList.Update) {
        withReactContent(Swal)
          .fire({
            icon: "warning",
            title: "Encaisser ?",
            confirmButtonText: "Encaisser",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await encaisserApi(ClientCode, rapportID, category, categoryKey);

              getInitialData(
                inputFilterValue.clientName,
                inputFilterValue.typeOfOp,
                inputFilterValue.E_I,
                inputFilterValue.formDate,
                inputFilterValue.toDate,
                selectedYear,
                inputFilterValue.clientCode
              );

              Swal.fire("Encaissée", "", "success");
            }
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleUndoImpayer = async (
    ClientCode,
    rapportID,
    category,
    categoryKey
  ) => {
    try {
      if (accessList.Update) {
        withReactContent(Swal)
          .fire({
            icon: "warning",
            title: "Undo ?",
            confirmButtonText: "Undo",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await undoImpayerApi(
                ClientCode,
                rapportID,
                category,
                categoryKey
              );

              getInitialData(
                inputFilterValue.clientName,
                inputFilterValue.typeOfOp,
                inputFilterValue.E_I,
                inputFilterValue.formDate,
                inputFilterValue.toDate,
                selectedYear,
                inputFilterValue.clientCode
              );

              Swal.fire("succès", "", "success");
            }
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleUndoEncaisser = async (
    ClientCode,
    rapportID,
    category,
    categoryKey
  ) => {
    try {
      if (accessList.Update) {
        withReactContent(Swal)
          .fire({
            icon: "warning",
            title: "Undo ?",
            confirmButtonText: "Undo",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await undoEncaisserApi(
                ClientCode,
                rapportID,
                category,
                categoryKey
              );

              getInitialData(
                inputFilterValue.clientName,
                inputFilterValue.typeOfOp,
                inputFilterValue.E_I,
                inputFilterValue.formDate,
                inputFilterValue.toDate,
                selectedYear,
                inputFilterValue.clientCode
              );

              Swal.fire("succès", "", "success");
            }
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleFiltersInputChangeByYear = async (e) => {
    try {
      setSelectedYear(e.target.value);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFilterRecovryData = async () => {
    try {
      getInitialData(
        inputFilterValue.clientName,
        inputFilterValue.typeOfOp,
        inputFilterValue.E_I,
        inputFilterValue.formDate,
        inputFilterValue.toDate,
        selectedYear,
        inputFilterValue.clientCode
      );
    }
    catch (err) {
      console.log(err);
    }
  }

  return (
    <Card extra={"w-full pb-10 p-4 h-full"}>
      <Dialog size="xl" open={showEditeRecouvry} handler={handleRecovryModal}>
        <DialogHeader>
          <p className="w-full text-center"></p>
        </DialogHeader>
        <DialogBody>
          <EditeRecouvry
            selectedRecouvry={selectedRecouvry}
            updateData={handleUpdateData}
            accessList={accessList}
          />
        </DialogBody>
      </Dialog>

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-5">
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Filtrer par client :
          </small>
          <div>
            <input
              type="text"
              id=""
              placeholder="Tout"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="clientName"
              value={inputFilterValue.clientName}
              onChange={handleFiltersInputChange}
            />
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Filtrer par type d'opération :
          </small>
          <div>
            <select
              label="select Version"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              defaultValue=""
              name="typeOfOp"
              value={inputFilterValue.typeOfOp}
              onChange={handleFiltersInputChange}
              // onClick={handleFiltersInputChange}
            >
              <option value="">Tout</option>
              <option value="Cheque">Chèque</option>
              <option value="Effet">Effet</option>
              <option value="Espece">Espèce</option>
              <option value="Virement">Virement</option>
            </select>
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Filtrer par Impayé / Encaissé :
          </small>
          <div>
            <select
              label="select Version"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              defaultValue=""
              name="E_I"
              value={inputFilterValue.E_I}
              onChange={handleFiltersInputChange}
              // onClick={handleFiltersInputChange}
            >
              <option value="">Tout</option>
              <option value="inProgress">En cours</option>
              <option value="impayer">Impayé</option>
              <option value="encaissement">Encaissé</option>
            </select>
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Filtrer par année :
          </small>
          <div>
            <select
              label="select Version"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              defaultValue=""
              name="selectedYear"
              value={selectedYear}
              onChange={handleFiltersInputChangeByYear}
            >
              {rangeOfYears.map((el) => (
                <option value={el}>{el}</option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Filtrer par date :
          </small>
          <div>
            <input
              type="date"
              id=""
              placeholder=""
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="formDate"
              value={inputFilterValue.formDate}
              onChange={handleFiltersInputChange}
            />
            <input
              type="date"
              id=""
              placeholder=""
              className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="toDate"
              value={inputFilterValue.toDate}
              onChange={handleFiltersInputChange}
            />
          </div>
        </div>
        <div>
        <Button
            className="linear mb-5 rounded-[10px] bg-green-500 px-4 py-2 text-xs font-bold text-white transition duration-200 hover:bg-green-400 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:mb-0"
            onClick={handleFilterRecovryData}
          >
            Filtrer
          </Button>
        </div>
      </div>

      {isLoading ? (
        <div className="flex w-full items-center justify-center">
          <Spinner className="h-12 w-12" />
        </div>
      ) : (
        <div
          className="mt-8 overflow-x-scroll"
          style={{ height: "50vh", overflowY: "scroll" }}
        >
          <table className="w-full">
            <thead>
              <tr>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">Date</p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">
                    Type d'operation
                  </p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">Bank</p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">Client</p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">N°</p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">Montant</p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">
                    Date d'échéance
                  </p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">Actions</p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">IMPAYÉ</p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">
                    ENCAISSÉ
                  </p>
                </th>
              </tr>
            </thead>

            <tbody>
              {listOfRecouvry.map((el) => (
                <tr>
                  <td className="px-2 pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {el.value.createdAt
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join("-")}
                    </p>
                  </td>
                  <td className="px-2 pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {el.key == "Cheque"
                        ? "Chèque"
                        : el.key == "Effet"
                        ? "Effet"
                        : el.key == "Espece"
                        ? "Espèce"
                        : el.key == "Virement"
                        ? "Virement"
                        : el.key}
                    </p>
                  </td>
                  <td className="px-2 pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {el.value.Bank}
                    </p>
                  </td>
                  <td className="px-2 pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {el.value.client}
                    </p>
                  </td>
                  <td className="px-2 pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      N°{el.value.transactionNumber}
                    </p>
                  </td>
                  <td className="px-2 pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {parseFloat(el.value.Montant).toFixed(2)}
                    </p>
                  </td>
                  <td className="px-2 pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {el?.value?.DateEcheance?.split("-").reverse().join("-")}
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {!el.value.encaissement ? (
                        <div className="flex">
                          {accessList.Read && (
                            <IoEyeSharp
                              size={20}
                              color="gray"
                              className="cursor-pointer"
                              onClick={() =>
                                handleShowRecovryReport(el.value.id, el.key)
                              }
                            />
                          )}
                          {(el.key == "Cheque" || el.key == "Effet") &&
                          accessList.Update ? (
                            <HiPencilAlt
                              size={20}
                              color="#236571"
                              className="ml-5 cursor-pointer"
                              onClick={() =>
                                handleRecovryModal(
                                  el.key,
                                  el.value.id,
                                  el.value.key
                                )
                              }
                            />
                          ) : (
                            <></>
                          )}
                          {accessList.Delete && (
                            <FaTrash
                              size={20}
                              color="#cf503f"
                              className="ml-5 cursor-pointer"
                              onClick={() =>
                                handleDeleteRecovry(
                                  el.value.id,
                                  el.key,
                                  el.value.key
                                )
                              }
                            />
                          )}
                        </div>
                      ) : (
                        <div className="flex flex-col">
                          {accessList.Undo && (
                            <>
                              {el.value.impayer && (
                                <Button
                                  variant="gradient"
                                  color="#cf503f"
                                  onClick={() =>
                                    handleUndoImpayer(
                                      el.value.codeClient,
                                      el.value.id,
                                      el.key,
                                      el.value.key
                                    )
                                  }
                                  className="mb-2 w-[75%] py-2"
                                >
                                  <span>Undo I</span>
                                </Button>
                              )}
                              {el.value.encaissement && (
                                <Button
                                  variant="gradient"
                                  color="#236571"
                                  onClick={() =>
                                    handleUndoEncaisser(
                                      el.value.codeClient,
                                      el.value.id,
                                      el.key,
                                      el.value.key
                                    )
                                  }
                                  className="w-[75%] py-2"
                                >
                                  <span>Undo E</span>
                                </Button>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <Button
                      variant="gradient"
                      color="#cf503f"
                      onClick={() =>
                        handleImpayer(
                          el.value.codeClient,
                          el.value.id,
                          el.key,
                          el.value.key
                        )
                      }
                      className="py-2"
                      disabled={
                        el.value.impayer ||
                        el.value.encaissement ||
                        !accessList.Update
                      }
                    >
                      <span>
                        {el.value.impayer || el.value.encaissement
                          ? "Impayé"
                          : "Impayer"}{" "}
                      </span>
                    </Button>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <Button
                      variant="gradient"
                      color="#236571"
                      onClick={() =>
                        handleEncaisser(
                          el.value.codeClient,
                          el.value.id,
                          el.key,
                          el.value.key
                        )
                      }
                      className="py-2"
                      disabled={el.value.encaissement || !accessList.Update}
                    >
                      <span>
                        {" "}
                        {el.value.encaissement ? "Encaissé" : "Encaisser"}
                      </span>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Card>
  );
}

export default RecouvryTable;
