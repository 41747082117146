import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  Spinner,
  IconButton,
} from "@material-tailwind/react";

import {
  IoMdArrowDropleftCircle,
  IoMdArrowDroprightCircle,
} from "react-icons/io";

import ReportDetails from "../ReportDetails";
import { getAllReportsListApi, updateReportDataApi } from "api/gestionStock";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getOneRapportProductsDetailsApi } from "api/gestionStock";
import { updateStockApi } from "api/stock";

function AfterInvoce({ selected, filterInputs, accessList }) {
  const [openReportDetails, setOpenReportDetails] = useState(false);

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [reportsList, setReportsList] = useState([]);
  const [isLoading, setIsLoading] = useState([]);

  const [active, setActive] = useState(1);
  const [currentPagination, setCurrentPagination] = useState(1);

  const [numberOfPages, setNumberOfPages] = useState(0);
  const [numberOfPaginations, setNumberOfPaginations] = useState(0);
  const [numberOfReports, setNumberOfReports] = useState(0);

  const [selectedReportId, setSelectedReportId] = useState({})

  useEffect(() => {
    getInitialData(
      1,
      currentYear,
      filterInputs.MP,
      filterInputs.clientName,
      "V",
      "V",
      "V",
      filterInputs.etatMagasin,
      ""
    );
    setActive(1);
    setCurrentPagination(1)
  }, [selected, filterInputs]);

  const getInitialData = async (
    page,
    currentYear,
    MP,
    clientName,
    etatMagasin,
    EtatBonLivraison,
    EtatFacturation,
    EtatMagasinierTwo,
    EtatValidationB
  ) => {
    try {
      setIsLoading(true);
      const reportsData = await getAllReportsListApi(
        page,
        currentYear,
        MP,
        clientName,
        etatMagasin,
        EtatBonLivraison,
        EtatFacturation,
        EtatMagasinierTwo,
        EtatValidationB
      );
      setReportsList(reportsData);

      setNumberOfPages(reportsData.pagination.numberOfPages);
      setNumberOfPaginations(reportsData.pagination.numberOfPaginations);
      setNumberOfReports(reportsData.pagination.numberOfClients);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleValidateReport = (reportID) => {
    try {
      withReactContent(Swal)
        .fire({
          icon: "warning",
          title: "Voulez-vous vraiment valider cette opération ?",
          confirmButtonText: "Valider",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            setIsLoading(true);
            const updatedObject = {
              EtatMagasinierTwo: "V",
            };
            await updateReportDataApi(reportID, updatedObject)
            // await updateFCreatedTApi(reportID)

            getInitialData(active, currentYear, filterInputs.MP, filterInputs.clientName, "V", "V", "V", filterInputs.etatMagasin, "")
            Swal.fire("Validée", "", "success");
          }
        });
    } catch (err) {
      console.log(err);
    }
    finally {
      setIsLoading(false);
    }
  }

  const handleCancelReport = async (report) => {
    try {
      const selectedProductsList = []
      const reportDetails = await getOneRapportProductsDetailsApi(report._id)
      const reportDepots = reportDetails.MagasinStock

      if(report.ModeOperation === "Vente") {
        Object.keys(reportDetails.Rapport).forEach(keys => {
          reportDetails.Rapport[keys].produits.map(el => {
            selectedProductsList.push({
              Name: el.name,
              FromD: null,
              ToD: reportDepots.find(depot => depot.productName === el.name).city,
              Quantity: el.quantiteByUnity,
              Type: "Avoir",
              Comment: "",
              TypeOfEnter: "Enter",
              FromReport: report._id,
              Client: report.fromClient,
              Operator: report.for
            })
          })
        })
      }
      else if(report.ModeOperation === "Avoir") {
        Object.keys(reportDetails.Rapport).forEach(keys => {
          reportDetails.Rapport[keys].produits.map(el => {
            selectedProductsList.push({
              Name: el.name,
              FromD: reportDepots.find(depot => depot.productName === el.name).city,
              ToD: null,
              Quantity: el.quantiteByUnity,
              Type: "Avoir",
              Comment: "",
              TypeOfEnter: "Exit",
              FromReport: report._id,
              Client: report.fromClient,
              Operator: report.for
            })
          })
        })
      }

      withReactContent(Swal)
        .fire({
          icon: "warning",
          title: "Voulez-vous vraiment annuler cette opération ?",
          confirmButtonText: "Annuler",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            setIsLoading(true);
            const updatedObject = {
              EtatMagasinierTwo: "A",
            };
            await updateReportDataApi(report._id, updatedObject)
            // await updateFCreatedTApi(report._id)

            await updateStockApi(selectedProductsList, "save");

            getInitialData(active, currentYear, filterInputs.MP, filterInputs.clientName, "V", "V", filterInputs.etatMagasin, "", "")
            Swal.fire("Annulée", "", "success");
          }
        });
    } catch (err) {
      console.log(err);
    }
    finally {
      setIsLoading(false);
    }
  }

  const handleOpenReportDetails = (id) => {
    if(accessList.Read) {
      setSelectedReportId(id)
      setOpenReportDetails(!openReportDetails);
    }
  };

  const getItemProps = (index) => ({
    variant: active === index ? "filled" : "text",
    color: "gray",
    onClick: () => {
      getInitialData(
        index,
        currentYear,
        filterInputs.MP,
        filterInputs.clientName,
        "V",
        "V",
        "V",
        filterInputs.etatMagasin,
        ""
      );
      setActive(index);
    },
  });

  const next = () => {
    if (currentPagination === numberOfPaginations) return;
    setCurrentPagination(currentPagination + 1);
  };

  const prev = () => {
    if (currentPagination === 1) return;
    setCurrentPagination(currentPagination - 1);
  };

  return (
    <div>
      <div style={{ height: "50vh", overflowY: "scroll" }}>
        <Dialog
          size="xl"
          open={openReportDetails}
          handler={handleOpenReportDetails}
        >
          <DialogHeader>
            <p className="w-full text-center">Vente (s)</p>
          </DialogHeader>
          <DialogBody>
            <ReportDetails typeOf="AI" selectedReportId={selectedReportId} />
          </DialogBody>
        </Dialog>

        {isLoading ? (
          <div className="flex w-full items-center justify-center">
            <Spinner className="h-12 w-12" />
          </div>
        ) : (
          reportsList?.data.map((el) => (
            <Card className="mt-6 w-full cursor-pointer dark:bg-indigo-900">
              <CardBody onClick={() => handleOpenReportDetails(el._id)}>
                <Typography
                  variant="h5"
                  color="blue-gray"
                  className="mb-2 dark:text-white"
                >
                  {el.InfoGenerale.NomSociete} - {el.RF}
                </Typography>
                <Typography style={{ fontWeight: 'bold' }}>
                  {Object.getOwnPropertyNames(el.Rapport).join(' - ')}
                </Typography>
                <Typography>
                  {el.ModeOperation} / {el.CommercialName}
                </Typography>
                <Typography>{el.InfoGenerale.Adress}</Typography>
                <Typography>
                  {el.CurrentDate} - {el.CurrentTime}
                </Typography>
              </CardBody>
              {el.EtatMagasinierTwo == "N" ? (
                <CardFooter className="flex pt-0">
                  <Button variant="gradient" color="#236571" className="mr-1" onClick={() => handleValidateReport(el._id)} disabled={!accessList.Update}>
                    <span>Valider</span>
                  </Button>
                  <Button variant="text" color="#cf503f" className="mr-1" onClick={() => handleCancelReport(el)} disabled={!accessList.Update}>
                    <span>Annuler</span>
                  </Button>
                </CardFooter>
              ) : el.EtatMagasinierTwo == "V" ? (
                <CardFooter className="flex pt-0">
                  <Button
                    variant="gradient"
                    color="#236571"
                    className="mr-1"
                    disabled
                  >
                    <span>Validée</span>
                  </Button>
                </CardFooter>
              ) : (
                <CardFooter className="flex pt-0">
                  <Button
                    variant="gradient"
                    color="#236571"
                    className="mr-1"
                    disabled
                  >
                    <span>Annulée</span>
                  </Button>
                </CardFooter>
              )}
            </Card>
          ))
        )}
      </div>

      {!isLoading && numberOfReports != 0 && (
        <div className="mt-4 flex items-center gap-4">
          <Button
            variant="text"
            className="flex items-center gap-2"
            onClick={prev}
            disabled={currentPagination === 1}
          >
            <IoMdArrowDropleftCircle size={30} />
          </Button>
          <div className="flex items-center gap-2">
            {currentPagination !== numberOfPaginations
              ? // paginations row
                Array.from({
                  length: numberOfPages > 5 ? 5 : numberOfPages,
                }).map((el, index) => {
                  const pageNumber = index + 1 + (currentPagination - 1) * 5;
                  return (
                    <IconButton {...getItemProps(pageNumber)}>
                      {pageNumber}
                    </IconButton>
                  );
                })
              : // last pagination row
                Array.from({ length: numberOfPages % 5 }).map((el, index) => {
                  const pageNumber = index + 1 + (currentPagination - 1) * 5;
                  return (
                    <IconButton {...getItemProps(pageNumber)}>
                      {pageNumber}
                    </IconButton>
                  );
                })}
          </div>
          <Button
            variant="text"
            className="flex items-center gap-2"
            onClick={next}
            disabled={
              currentPagination === numberOfPaginations ||
              numberOfPaginations === 0
            }
          >
            <IoMdArrowDroprightCircle size={30} />
          </Button>
        </div>
      )}
    </div>
  );
}

export default AfterInvoce;
