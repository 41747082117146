import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemPrefix,
  Switch,
  Radio,
} from "@material-tailwind/react";

import { MdDelete } from "react-icons/md";

import { addNewRoleApi, updateRoleApi } from "api/operatorRole";
import { operatorsAccessList } from "api/filtersData";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { ToastContainer, toast } from "react-toastify";
import { ckeckRoleBeforeAdd, ckeckRoleBeforeEdite } from "api/operatorRole";

import { socket } from "socket";

function Role_details({ closePopup, roleModalAction, selectedRoleData }) {
  const [sideNavItems, setSideNavItems] = useState([]);
  const [selectedSideNavItems, setSelectedSideNavItems] = useState([]);

  const [selectedAccess, setSelectedAccess] = useState("");
  const [selectedRoleName, setSelectedRoleName] = useState("");

  const [mobileAccess, setMobileAccess] = useState(false);

  const [mobileAccessObject, setMobileAccessObject] = useState({
    isDG: false,
    isDC: false,
    isC: false,
    isTD: false,
  });

  useEffect(() => {
    getInitialData();
  }, [roleModalAction, selectedRoleData]);

  const getInitialData = async () => {
    try {
      const response = await operatorsAccessList();
      setSideNavItems(response);

      if(roleModalAction !== "new") {
        setSelectedRoleName(selectedRoleData.name)
        console.log("selectedRoleData.name =>", selectedRoleData.name)
        if(selectedRoleData.isDG || selectedRoleData.isDC || selectedRoleData.isC || selectedRoleData.isTD) {
          setMobileAccess(true)
          setMobileAccessObject({
            isDG: selectedRoleData.isDG,
            isDC: selectedRoleData.isDC,
            isC: selectedRoleData.isC,
            isTD: selectedRoleData.isTD,
          })
        }
        setSelectedSideNavItems(selectedRoleData.access)
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleMobileAccessObjectChange = (e) => {
    const { name, value } = e.target;
    setMobileAccessObject((prevMobileAccessObject) => ({
      ...prevMobileAccessObject,
      isDG: value === "isDG" ? true : false,
      isDC: value === "isDC" ? true : false,
      isC: value === "isC" ? true : false,
      isTD: value === "isTD" ? true : false,
    }));
  };

  const handleMobileAccessChange = () => {
    try {
      setMobileAccess(!mobileAccess);

      setMobileAccessObject({
        isDG: false,
        isDC: false,
        isC: false,
        isTD: false
      })
    } catch (err) {
      console.log(err);
    }
  };

  const handleEditeRole = async () => {
    const filtredSelectedSideNavItems = []

    selectedSideNavItems.map(el => {
      const isNotChecked = el.sectionItems.every(subItem => subItem.checked === false)
      if(!isNotChecked) {
        filtredSelectedSideNavItems.push(el)
      }
    })

    try {
      await ckeckRoleBeforeEdite(
        selectedRoleName,
        filtredSelectedSideNavItems,
        mobileAccess,
        mobileAccessObject,
        selectedRoleData._id
      );

      withReactContent(Swal)
        .fire({
          icon: "info",
          title: "voulez-vous modifier ce rôle ?",
          showCancelButton: true,
          cancelButtonText: "Annuler",
          confirmButtonText: "Modifier",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await updateRoleApi(
              selectedRoleName,
              filtredSelectedSideNavItems,
              mobileAccess,
              mobileAccessObject,
              selectedRoleData._id
            );

            // socket management
            socket.emit('EditeRole')

            Swal.fire("Modifiée", "", "success");
            toast.dismiss();

            closePopup("Reload");
          }
        });
    }
    catch (error) {
      return toast.error(`${error.response.data.error}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const handleAddNewRole = async () => {
    const filtredSelectedSideNavItems = []

    selectedSideNavItems.map(el => {
      const isNotChecked = el.sectionItems.every(subItem => subItem.checked === false)
      if(!isNotChecked) {
        filtredSelectedSideNavItems.push(el)
      }
    })

    // filtredSelectedSideNavItems.map(el => {
    //   el.sectionItems = el.sectionItems.filter(subItem => subItem.checked === true)
    // })

    try {
      await ckeckRoleBeforeAdd(
        selectedRoleName,
        filtredSelectedSideNavItems,
        mobileAccess,
        mobileAccessObject
      );

      withReactContent(Swal)
        .fire({
          icon: "info",
          title: "voulez-vous ajouter ce rôle ?",
          showCancelButton: true,
          cancelButtonText: "Annuler",
          confirmButtonText: "Ajouter",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await addNewRoleApi(
              selectedRoleName,
              filtredSelectedSideNavItems,
              mobileAccess,
              mobileAccessObject
            );

            Swal.fire("Ajoutée", "", "success");
            toast.dismiss();

            closePopup("Reload");
          }
        });
    } catch (error) {
      return toast.error(`${error.response.data.error}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleCancelOp = () => {
    try {
      closePopup("Close");
      toast.dismiss();
    } catch (error) {}
  };

  const handleAddNewAccess = () => {
    try {
      const sideNavItem = sideNavItems.find((el) => el._id === selectedAccess);

      const checkIfAlreadyExist = selectedSideNavItems.find(
        (el) => el.value === selectedAccess
      );

      if (checkIfAlreadyExist) {
        return toast.error("Existe déjà !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      const newAccessObject = {
        label: sideNavItem.name,
        path: sideNavItem.path,
        value: sideNavItem._id,
        sectionItems: sideNavItem.sectionItems,
      };

      setSelectedSideNavItems((prevState) => [...prevState, newAccessObject]);
    } catch (error) {}
  };

  const handleDeleteAccess = (id) => {
    try {
      setSelectedSideNavItems((prevState) =>
        prevState.filter((item) => item.value !== id)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleAccessChange = (e) => {
    setSelectedAccess(e.target.value);
  };

  const handleRoleNameChange = (e) => {
    setSelectedRoleName(e.target.value);
  };

  const handleCheckboxChange = (index, itemIndex) => {
    setSelectedSideNavItems((prevState) =>
    prevState.map((item, idx) => {
      if (index === idx) {
        const updatedSectionItems = item.sectionItems.map((sectionItem, siIndex) => {
          if (siIndex === itemIndex) {
            return {
              ...sectionItem,
              checked: !sectionItem.checked
            };
          }
          return sectionItem;
        });

        return {
          ...item,
          sectionItems: updatedSectionItems
        };
      }
      return item;
    })
  );

    // setSelectedSideNavItems((prevState) =>
    //   prevState.map((item, idx) => {
    //     if(index === idx) {
    //       console.log(item.sectionItems[itemIndex].label)
    //     }
    //     return (
    //       {
    //         ...item,
    //         [item.sectionItems[itemIndex].label]: !item.sectionItems[itemIndex].checked
    //       }
    //     )
    //   }
    //   )
    // );

    // console.log("selectedSideNavItems", selectedSideNavItems)
  };

  return (
    <div className="newRoleModale">
      <ToastContainer />
      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div></div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Nom de votre rôle
          </label>
          <input
            type="text"
            id=""
            placeholder="Rôle"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            onChange={handleRoleNameChange}
            value={selectedRoleName}
            disabled={roleModalAction === "see"}
          />
        </div>
        <div></div>
      </div>

      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div className="md:col-span-1">

          {
            roleModalAction !== "see" ?
            <>
              <label htmlFor="" className="text-md font-bold text-navy-700">
                Liste des accès
              </label>
              <select
                label="select Version"
                className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
                onChange={handleAccessChange}
              >
                <option value="Tout" defaultChecked>
                  Sélectionner un accès
                </option>
                {sideNavItems.map((el) => (
                  <option value={el._id}>{el.name}</option>
                ))}
              </select>
              <Button
                variant="gradient"
                color="#236571"
                onClick={() => handleAddNewAccess()}
                className="mt-2 w-full"
              >
                <span>Ajouter</span>
              </Button>
            </> :
            <></>
          }

          <div className="mt-3">
            <small className="text-sm font-bold text-navy-700 dark:text-white">
              <p className="text-center">Accès mobile</p>
            </small>
            <div className="flex w-full items-center justify-center">
              <Switch
                checked={mobileAccess}
                onChange={handleMobileAccessChange}
                disabled={roleModalAction === "see"}
              />
            </div>
          </div>

          {mobileAccess && (
            <div className="grid grid-cols-1 lg:grid-cols-2">
              <Radio
                name="mobileAccess"
                value="isDG"
                onChange={handleMobileAccessObjectChange}
                label="Directeur général"
                checked={mobileAccessObject.isDG}
                disabled={roleModalAction === "see"}
              />
              <Radio
                name="mobileAccess"
                value="isDC"
                onChange={handleMobileAccessObjectChange}
                label="Directeur commercial"
                checked={mobileAccessObject.isDC}
                disabled={roleModalAction === "see"}
              />
              <Radio
                name="mobileAccess"
                value="isC"
                onChange={handleMobileAccessObjectChange}
                label="Commercial"
                checked={mobileAccessObject.isC}
                disabled={roleModalAction === "see"}
              />
              <Radio
                name="mobileAccess"
                value="isTD"
                onChange={handleMobileAccessObjectChange}
                label="Département technique"
                checked={mobileAccessObject.isTD}
                disabled={roleModalAction === "see"}
              />
            </div>
          )}
        </div>
        <div className="md:col-span-2">
          <p className="text-md text-center font-bold text-navy-700">
            Liste des accès sélectionner
          </p>

          <div className="accessListCard mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
            {selectedSideNavItems.map((el, index) => (
              <Card className="w-full">
                <CardBody>
                  <Typography variant="h5" color="blue-gray" className="mb-2">
                    {el.label}
                  </Typography>
                  <Typography></Typography>
                </CardBody>
                <CardFooter className="pt-0">
                  <div className="">
                    <List className="grid grid-cols-1 flex-row gap-5 sm:grid-cols-2">
                      {el?.sectionItems?.map((items, itemIndex) => (
                        <ListItem className="p-0" key={itemIndex}>
                          <label
                            htmlFor={`horizontal-list-${items?._id}`}
                            className="flex w-full cursor-pointer items-center py-2"
                          >
                            <ListItemPrefix className="mr-3">
                              <Checkbox
                                checked={items.checked}
                                onChange={() =>
                                  handleCheckboxChange(index, itemIndex)
                                }
                                disabled={roleModalAction === "see"}
                                id={`horizontal-list-${items?._id}`}
                                ripple={false}
                                className="hover:before:opacity-0"
                                containerProps={{
                                  className: "p-0",
                                }}
                              />
                            </ListItemPrefix>
                            <Typography
                              color="blue-gray"
                              className="font-medium"
                            >
                              {items?.label}
                            </Typography>
                          </label>
                        </ListItem>
                      ))}
                    </List>

                    {
                      roleModalAction !== "see" &&
                      <div className="flex justify-center">
                        <MdDelete
                          size={40}
                          color="#cf503f"
                          className="cursor-pointer"
                          onClick={() => handleDeleteAccess(el.value)}
                        />
                      </div>
                    }

                  </div>
                </CardFooter>
              </Card>
            ))}
          </div>
        </div>
      </div>

      {roleModalAction == "new" ? (
        <div className="mt-5">
          <Button
            variant="gradient"
            color="#236571"
            onClick={() => handleAddNewRole()}
            className="mr-1"
          >
            <span>Ajouter</span>
          </Button>
          <Button
            variant="text"
            color="#cf503f"
            onClick={() => handleCancelOp()}
            className="mr-1"
          >
            <span>Annuler</span>
          </Button>
        </div>
      ) : roleModalAction == "edite" ? (
        <div className="mt-5">
          <Button
            variant="gradient"
            color="#236571"
            className="mr-1"
            onClick={() => handleEditeRole()}
          >
            <span>Modifier</span>
          </Button>
          <Button
            variant="text"
            color="#cf503f"
            onClick={() => handleCancelOp()}
            className="mr-1"
          >
            <span>Annuler</span>
          </Button>
        </div>
      ) : (
        <div className="mt-5">
          <Button
            variant="text"
            color="#cf503f"
            onClick={() => handleCancelOp()}
            className="mr-1"
          >
            <span>Annuler</span>
          </Button>
        </div>
      )}
    </div>
  );
}

export default Role_details;
