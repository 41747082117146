import React, { useEffect, useRef, useState } from "react";
import Card from "components/card";

import EssayCard from "./EssayCard";

import {
  Tabs,
  TabsHeader,
  TabsBody,
  // Tab,
  TabPanel,
  Spinner,
  Button,
} from "@material-tailwind/react";
import { getAllEssayReportsApi } from "api/essay";

// const TabsData = [
//   {
//     label: "Maroc",
//     value: "Morocco",
//     desc: <EssayCard />
//   },
// ];

function EssayList({ accessList, isAdmin }) {
  const [isLoading, setIsLoading] = useState(false);

  const [TabsData, setTabsData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [filtredObject, setFiltredObject] = useState({
    "ClientName": "",
    "Variete": "",
  });

  useEffect(() => {
    // Focus the input element when the component mounts
    if (inputRef.current) {
      inputRef.current.focus();
    }
    getInitialData(filtredObject);
  }, [accessList, isAdmin]);

  const inputRef = useRef(null);

  const handleInputChange = e => {
    const {name, value} = e.target
    setFiltredObject({...filtredObject, [name]: value})
  }

  const handleShowToast = async () => {
    getInitialData(filtredObject);
  };

  const getInitialData = async (filtredObject) => {
    try {
      setIsLoading(true);

      const response = await getAllEssayReportsApi(filtredObject);
      setTableData(response);

      setTabsData([
        {
          label: "Maroc",
          value: "Morocco",
          desc: (
            <EssayCard
              tableData={response}
              accessList={accessList}
              isAdmin={true}
              showToast={handleShowToast}
              reloadData={handleReloadData}
            />
          ),
        },
      ]);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterInputData = () => {
    try {
      getInitialData(filtredObject);
    }
    catch (err) {

    }
  }

  const handleReloadData = () => {
    try {
      getInitialData(filtredObject);
    }
    catch (err) {
      console.log(err)
    }
  }

  return (
    <div>
      {isLoading ? (
        <div className="mt-[10%] flex w-full items-center justify-center">
          <Spinner className="h-12 w-12" />
        </div>
      ) : (
        <Card extra={"w-full pb-10 p-4 h-full"}>
          <header className="relative flex items-center justify-between">
            <div></div>
          </header>

          <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-3">
            <div>
              <small className="text-sm font-medium text-gray-600 dark:text-white">
                Filtrer par client
              </small>
              <div>
                <input
                  type="text"
                  id=""
                  placeholder=""
                  className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

                  name="ClientName"
                  value={filtredObject.ClientName}
                  onChange={handleInputChange}
                  ref={inputRef}
                />
              </div>
            </div>

            <div>
              <small className="text-sm font-medium text-gray-600 dark:text-white">
                Filtrer par variété
              </small>
              <div>
                <input
                  type="text"
                  id=""
                  placeholder=""
                  className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

                  name="Variete"
                  value={filtredObject.Variete}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <Button
                variant="gradient"
                color="#236571"
                onClick={() => handleFilterInputData()}
                className="max-h-[40px] max-w-[50%] mt-7"
              >
                <span>Filtrer</span>
              </Button>
          </div>

          <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2"></div>

          <div
            className="mt-8 overflow-x-scroll xl:overflow-x-hidden"
            style={{ height: "60vh", overflowY: "scroll" }}
          >
            <Tabs value="Morocco">
              <TabsHeader className="flex flex-col md:flex-row">
                {/* {TabsData.map(({ label, value }) => (
        <Tab key={value} value={value} className='mb-5 md:mb-0'>
          <p className='font-semibold text-gray-700 dark:text-indigo-500'>{label}</p>
        </Tab>
      ))} */}
              </TabsHeader>
              <TabsBody>
                {TabsData.map(({ value, desc }) => (
                  <TabPanel key={value} value={value}>
                    {desc}
                  </TabPanel>
                ))}
              </TabsBody>
            </Tabs>
          </div>
        </Card>
      )}
    </div>
  );
}

export default EssayList;

// EssayList
