import React, { useEffect, useState } from 'react'
import { Button } from '@material-tailwind/react';
import { addNewDepotApi } from 'api/depot';
import { updateDepotApi } from 'api/depot';

function DepotForm({ closePopup, depotFormAction, showToast, selectedDepot }) {
  const [depotShortName, setDepotShortName] = useState('')
  const [depotName, setDepotName] = useState('')

  useEffect(() => {
    getInitialData()
  }, [selectedDepot])

  const getInitialData = () => {
    try {
      // add show edite
      setDepotShortName(selectedDepot.Name)
      setDepotName(selectedDepot.Origin)
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleAddDepot = async () => {
    try {
      await addNewDepotApi(depotShortName, depotName)
      showToast('success', 'Dépôt Ajoutée')
      closePopup("Reload");
    }
    catch (err) {
      showToast('error', `${err?.response?.data?.error}`)
    }
  };

  const handleEditeDepot = async () => {
    try {
      await updateDepotApi(depotShortName, depotName, selectedDepot._id)
      showToast('success', 'Dépôt Modifiée')
      closePopup("Reload");
    }
    catch (err) {
      showToast('error', `${err?.response?.data?.error}`)
    }
  }

  const handleCancelOp = () => {
    closePopup("Close");
  };

  return (
    <form>
      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Nom du dépôt
          </label>
          <input
            type="text"
            id=""
            placeholder="Nom du dépôt (en 3 caractères)"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            maxLength={3}
            value={depotShortName}
            disabled={depotFormAction === "show"}

            onChange={e => setDepotShortName(e.target.value)}
          />
        </div>

        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Nom complet du dépôt
          </label>
          <input
            type="text"
            id=""
            placeholder="Nom complet du dépôt"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            value={depotName}
            disabled={depotFormAction === "show"}

            onChange={e => setDepotName(e.target.value)}
          />
        </div>
      </div>

      {
        depotFormAction === "edite" &&
        <div className="mt-5">
        <Button
          variant="gradient"
          color="#236571"
          onClick={() => handleEditeDepot()}
          className="mr-1"
        >
          <span>Modifier</span>
        </Button>
        <Button
          variant="text"
          color="#cf503f"
          onClick={() => handleCancelOp()}
          className="mr-1"
        >
          <span>Annuler</span>
        </Button>
        </div>
      }

      {
        depotFormAction === "add" &&
        <div className="mt-5">
        <Button
          variant="gradient"
          color="#236571"
          onClick={() => handleAddDepot()}
          className="mr-1"
        >
          <span>Ajouter</span>
        </Button>
        <Button
          variant="text"
          color="#cf503f"
          onClick={() => handleCancelOp()}
          className="mr-1"
        >
          <span>Annuler</span>
        </Button>
        </div>
      }
    </form>
  )
}

export default DepotForm
