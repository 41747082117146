import { Button, Switch } from "@material-tailwind/react";
import { updateReportDataApi } from "api/gestionStock";
import React, { useEffect, useState } from "react";

function OperationForm({ closePopup, selectedOp, showToast }) {
  const [InfoGenerale, setInfoGenerale] = useState({
    NomSociete: "",
    Tel: "",
    ICE: "",
    Adress: "",
    Email: "",
    ClientCode: "",
  })

  const [reportNumberBC, setReportNumberBC] = useState({
    modifiedFactureBC: false,
    factureBCValue: "",
  })

  const [reportNumberBL, setReportNumberBL] = useState({
    modifiedFactureBL: false,
    factureBLValue: "",
  })

  const [reportNumberF, setReportNumberF] = useState({
    modifiedFactureF: false,
    factureFValue: "",
  })

  useEffect(() => {
    getinitialData()
  }, [selectedOp])

  const getinitialData = () => {
    try {
      setInfoGenerale({
        NomSociete: selectedOp.InfoGenerale.NomSociete,
        Tel: selectedOp.InfoGenerale.Tel,
        ICE: selectedOp.InfoGenerale.ICE,
        Adress: selectedOp.InfoGenerale.Adress,
        Email: selectedOp.InfoGenerale.Email,
        ClientCode: selectedOp.InfoGenerale.ClientCode,
      })

      setReportNumberBC({
        modifiedFactureBC: selectedOp?.FactureBC?.modifiedFactureBC,
        factureBCValue: selectedOp?.FactureBC?.factureBCValue,
      })

      setReportNumberBL({
        modifiedFactureBL: selectedOp?.FactureBL?.modifiedFactureBL,
        factureBLValue: selectedOp?.FactureBL?.factureBLValue,
      })

      setReportNumberF({
        modifiedFactureF: selectedOp?.FactureF?.modifiedFactureF,
        factureFValue: selectedOp?.FactureF?.factureFValue
      })
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleInfoGeneraleInputChange = (e) => {
    const { name, value } = e.target;
    setInfoGenerale({ ...InfoGenerale, [name]: value });
  };

  const handleBCSwitchChange = (checked) => {
    setReportNumberBC({
      ...reportNumberBC,
      modifiedFactureBC: checked.target.checked,
    });
  };
  const handleBLSwitchChange = (checked) => {
    setReportNumberBL({
      ...reportNumberBL,
      modifiedFactureBL: checked.target.checked,
    });
  };
  const handleFSwitchChange = (checked) => {
    setReportNumberF({
      ...reportNumberF,
      modifiedFactureF: checked.target.checked,
    });
  };

  const handleCancelOp = () => {
    try {
      closePopup("Close");
    } catch (err) {}
  };

  const handleEditeOp = async () => {
    try {
      const updatedObject = {
        InfoGenerale: InfoGenerale,
        FactureBC: reportNumberBC,
        FactureBL: reportNumberBL,
        FactureF: reportNumberF,
      };
      await updateReportDataApi(selectedOp._id, updatedObject)

      showToast('success', `l'opération ${selectedOp.InfoGenerale.NomSociete} a été modifiée`)

      closePopup("Close")
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <form>
      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Nom de la société
          </label>
          <input
            type="text"
            id=""
            placeholder="Nom de la société"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

            name="NomSociete"
            value={InfoGenerale.NomSociete}
            onChange={handleInfoGeneraleInputChange}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Téléphone
          </label>
          <input
            type="text"
            id=""
            placeholder="Téléphone"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

            name="Tel"
            value={InfoGenerale.Tel}
            onChange={handleInfoGeneraleInputChange}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            ICE
          </label>
          <input
            type="text"
            id=""
            placeholder="ICE"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

            name="ICE"
            value={InfoGenerale.ICE}
            onChange={handleInfoGeneraleInputChange}
          />
        </div>
      </div>

      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Adresse
          </label>
          <input
            type="text"
            id=""
            placeholder="Adress"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

            name="Adress"
            value={InfoGenerale.Adress}
            onChange={handleInfoGeneraleInputChange}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            E-mail
          </label>
          <input
            type="text"
            id=""
            placeholder="E-mail"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

            name="Email"
            value={InfoGenerale.Email}
            onChange={handleInfoGeneraleInputChange}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Code client
          </label>
          <input
            type="text"
            id=""
            placeholder="Code client"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

            name="ClientCode"
            value={InfoGenerale.ClientCode}
            onChange={handleInfoGeneraleInputChange}
          />
        </div>
      </div>

      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            N° BC
          </label>
          <input
            type="text"
            id=""
            placeholder="N° BC"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

            name="factureBCValue"
            value={reportNumberBC.factureBCValue}
            onChange={(e) =>
              setReportNumberBC({
                ...reportNumberBC,
                factureBCValue: e.target.value,
              })
            }

            disabled={!reportNumberBC.modifiedFactureBC}
          />
        </div>
        <div className="flex flex-col mt-5">
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Activer la modification de N° BC
          </label>
          <Switch
            onChange={handleBCSwitchChange}
            checked={reportNumberBC.modifiedFactureBC}
          />
        </div>
      </div>
      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            N° BL
          </label>
          <input
            type="text"
            id=""
            placeholder="N° BL"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

            name="factureBLValue"
            value={reportNumberBL.factureBLValue}
            onChange={(e) =>
              setReportNumberBL({
                ...reportNumberBL,
                factureBLValue: e.target.value,
              })
            }

            disabled={!reportNumberBL.modifiedFactureBL}
          />
        </div>
        <div className="flex flex-col mt-5">
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Activer la modification de N° BL
          </label>
          <Switch
            onChange={handleBLSwitchChange}
            checked={reportNumberBL.modifiedFactureBL}
          />
        </div>
      </div>
      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            N° F
          </label>
          <input
            type="text"
            id=""
            placeholder="N° F"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            name="factureFValue"
            value={reportNumberF.factureFValue}
            onChange={(e) =>
              setReportNumberF({
                ...reportNumberF,
                factureFValue: e.target.value,
              })
            }
            disabled={!reportNumberF.modifiedFactureF}
          />
        </div>
        <div className="flex flex-col mt-5">
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Activer la modification de N° F
          </label>
          <Switch
            onChange={handleFSwitchChange}
            checked={reportNumberF.modifiedFactureF}
          />
        </div>
      </div>
      

      <div className="mt-5">
          <Button
            variant="gradient"
            color="#236571"
            onClick={() => handleEditeOp()}
            className="mr-1"
          >
            <span>Modifier</span>
          </Button>
          <Button
            variant="text"
            color="#cf503f"
            onClick={() => handleCancelOp()}
            className="mr-1"
          >
            <span>Annuler</span>
          </Button>
        </div>
    </form>
  );
}

export default OperationForm;
