import React, { useEffect, useState } from "react";
import { Button, Radio } from "@material-tailwind/react";

import { getAllTcpsApi } from "api/filtersData";
import {
  addNewClientApi,
  getOneClientApi,
  updateClientApi,
  existingDataValuesApi,
  existingUpdateDataValuesApi,
  addFormValidatorApi,
  updateFormValidatorApi,
} from "api/clients";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function ClientForm({ closePopup, clienFormAction, showToast, clientID }) {
  const [tcpData, setTcpData] = useState([]);
  const [clientType, setClientType] = useState("M"); // M F
  const [clientFormData, setClientFormData] = useState({
    ClientCode: "",
    Name: "",
    Phone: "",
    ICE: "",
    Mail: "",
    Adress: "",
    // Role: "M",
    Responsable: "",
    ResponsablePhone: "",
    ClientUCodeFor: "",

    CodeComptable: "",
    Deadline1: 0,
    Deadline2: 0,
    Plaphon1: 0,
    Plaphon2: 0,

    ClientSumOffFPrice: 0,
    ClientSumOffFPriceCurrent: 0,
    ClientReleveCredit: 0,
    clientAN: 0,
    clientSold: {},

    updatedClientSold: false,
    IsActive: true,
  });

  useEffect(() => {
    getAllTcpsData();
    getInitialClientData();
  }, [clientID]);

  const getInitialClientData = async () => {
    try {
      const clientDetails = await getOneClientApi(clientID);
      setClientType(clientDetails.Role)
      setClientFormData({
        ClientCode: clientDetails.ClientCode,
        Name: clientDetails.Name,
        Phone: clientDetails.Phone,
        ICE: clientDetails.ICE,
        Mail: clientDetails.Mail,
        Adress: clientDetails.Adress,
        // Role: "M",
        Responsable: clientDetails.Responsable,
        ResponsablePhone: clientDetails.ResponsablePhone,
        ClientUCodeFor: clientDetails.ClientUCodeFor,

        CodeComptable: clientDetails.CodeComptable,
        Deadline1: parseFloat(clientDetails.Deadline1 || 0),
        Deadline2: parseFloat(clientDetails.Deadline2 || 0),
        Plaphon1: parseFloat(clientDetails.Plaphon1 || 0),
        Plaphon2: parseFloat(clientDetails.Plaphon2 || 0),

        ClientSumOffFPrice: parseFloat(clientDetails.ClientSumOffFPrice || 0),
        ClientSumOffFPriceCurrent: parseFloat(
          clientDetails.ClientSumOffFPriceCurrent || 0
        ),
        ClientReleveCredit: parseFloat(clientDetails.ClientReleveCredit || 0),
        clientAN: parseFloat(clientDetails.clientAN || 0),
        clientSold: clientDetails.clientSold,

        updatedClientSold: clientDetails.updatedClientSold,
        IsActive: clientDetails.IsActive,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getAllTcpsData = async () => {
    try {
      const tcpData = await getAllTcpsApi();
      setTcpData(tcpData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClientFormData({ ...clientFormData, [name]: value });
  };

  const handleCancelOp = () => {
    try {
      closePopup("Close");
    } catch (err) {}
  };

  const handleAddNewClient = async () => {
    try {
      await addFormValidatorApi(clientFormData);
      const response = await existingDataValuesApi(clientFormData);
      if (
        response.isEmailExist ||
        response.isIceExist ||
        response.isResponsablePhoneExist ||
        response.isPhoneExist
      ) {
        withReactContent(Swal)
          .fire({
            icon: "warning",
            title: `Vous voulez ajouter cette cliente ${
              response.isEmailExist ? "*adresse e-mail" : ""
            } ${response.isIceExist ? "*ICE/CIN" : ""} ${
              response.isResponsablePhoneExist
                ? "*Téléphone du Responsable"
                : ""
            } ${response.isPhoneExist ? "*Téléphone" : ""} existe déjà`,
            confirmButtonText: "Ajouter",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              try {
                clientFormData.Role = clientType;
                await addNewClientApi(clientFormData);
                showToast("success", "Client ajoutée");

                // Swal.fire("Validée", "", "success");
              } catch (err) {
                Swal.fire(err?.response?.data?.error, "", "error");
              }
            }
          });
      } else {
        clientFormData.Role = clientType;
        await addNewClientApi(clientFormData);
        showToast("success", "Client ajoutée");
      }
    } catch (err) {
      showToast("error", `${err?.response?.data?.error}`);
    }
  };

  const handleUpdateClient = async () => {
    try {
      await updateFormValidatorApi(clientFormData, clientID);
      const response = await existingUpdateDataValuesApi(
        clientFormData,
        clientID
      );
      if (
        response.isEmailExist ||
        response.isIceExist ||
        response.isResponsablePhoneExist ||
        response.isPhoneExist
      ) {
        withReactContent(Swal)
          .fire({
            icon: "warning",
            title: `Vous voulez modifier cette cliente ${
              response.isEmailExist ? "*adresse e-mail" : ""
            } ${response.isIceExist ? "*ICE/CIN" : ""} ${
              response.isResponsablePhoneExist
                ? "*Téléphone du Responsable"
                : ""
            } ${response.isPhoneExist ? "*Téléphone" : ""} existe déjà`,
            confirmButtonText: "Modifier",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              try {
                clientFormData.Role = clientType;
                await updateClientApi(clientFormData, clientID);
                showToast("success", "Client modifiée");

                // Swal.fire("Validée", "", "success");
              } catch (err) {
                Swal.fire(err?.response?.data?.error, "", "error");
              }
            }
          });
      } else {
        clientFormData.Role = clientType;
        await updateClientApi(clientFormData, clientID);
        showToast("success", "Client modifiée");
      }
    } catch (err) {
      showToast("error", `${err?.response?.data?.error}`);
    }
  };

  return (
    <form
      style={{
        overflow: "scroll",
        height: "80vh",
      }}
    >
      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-2">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            TCP
          </label>
          <select
            label="select Version"
            className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            value={clientFormData?.ClientUCodeFor}
            name="ClientUCodeFor"
            onChange={handleInputChange}
          >
            <option disabled value="">
              Sélectionner le TCP
            </option>
            {tcpData.map((el) => (
              <option value={el._id}>{el.Name}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Type
          </label>
          <div className="flex gap-10">
            <Radio
              name="type"
              label="Entreprise"
              onClick={() => setClientType("M")}
              checked={clientType === "M"}
            />
            <Radio
              name="type"
              label="Personne physique"
              onClick={() => setClientType("F")}
              checked={clientType === "F"}
            />
          </div>
        </div>
      </div>

      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Code client
          </label>
          <input
            type="text"
            id=""
            placeholder="Code client"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            disabled={clienFormAction === "show"}
            value={clientFormData?.ClientCode}
            name="ClientCode"
            onChange={handleInputChange}
          />
        </div>

        {clientType === "M" && (
          <div>
            <label htmlFor="" className="text-md font-bold text-navy-700">
              Raison sociale
            </label>
            <input
              type="text"
              id=""
              placeholder="Raison sociale"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              disabled={clienFormAction === "show"}
              value={clientFormData?.Name}
              name="Name"
              onChange={handleInputChange}
            />
          </div>
        )}
        {clientType === "F" && (
          <div>
            <label htmlFor="" className="text-md font-bold text-navy-700">
              Prénom et nom
            </label>
            <input
              type="text"
              id=""
              placeholder="Prénom et nom"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              disabled={clienFormAction === "show"}
              value={clientFormData?.Name}
              name="Name"
              onChange={handleInputChange}
            />
          </div>
        )}

        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Téléphone
          </label>
          <input
            type="text"
            id=""
            placeholder="Téléphone"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            disabled={clienFormAction === "show"}
            value={clientFormData?.Phone}
            name="Phone"
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        {clientType === "M" && (
          <div>
            <label htmlFor="" className="text-md font-bold text-navy-700">
              ICE
            </label>
            <input
              type="text"
              id=""
              placeholder="ICE"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              disabled={clienFormAction === "show"}
              value={clientFormData?.ICE}
              name="ICE"
              onChange={handleInputChange}
            />
          </div>
        )}
        {clientType === "F" && (
          <div>
            <label htmlFor="" className="text-md font-bold text-navy-700">
              CIN
            </label>
            <input
              type="text"
              id=""
              placeholder="CIN"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              disabled={clienFormAction === "show"}
              value={clientFormData?.ICE}
              name="ICE"
              onChange={handleInputChange}
            />
          </div>
        )}

        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            E-Mail
          </label>
          <input
            type="text"
            id=""
            placeholder="E-Mail"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            disabled={clienFormAction === "show"}
            value={clientFormData?.Mail}
            name="Mail"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Adresse
          </label>
          <input
            type="text"
            id=""
            placeholder="Adresse"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            disabled={clienFormAction === "show"}
            value={clientFormData?.Adress}
            name="Adress"
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Responsable
          </label>
          <input
            type="text"
            id=""
            placeholder="Responsable"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            disabled={clienFormAction === "show"}
            value={clientFormData?.Responsable}
            name="Responsable"
            onChange={handleInputChange}
          />
        </div>
        {clientType === "M" && (
          <div>
            <label htmlFor="" className="text-md font-bold text-navy-700">
              Téléphone du Responsable
            </label>
            <input
              type="text"
              id=""
              placeholder="Téléphone du Responsable"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              disabled={clienFormAction === "show"}
              value={clientFormData?.ResponsablePhone}
              name="ResponsablePhone"
              onChange={handleInputChange}
            />
          </div>
        )}
        {clientType === "F" && (
          <div>
            <label htmlFor="" className="text-md font-bold text-navy-700">
              Numero de Téléphone (Personnel)
            </label>
            <input
              type="text"
              id=""
              placeholder="Numero de Téléphone"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              disabled={clienFormAction === "show"}
              value={clientFormData?.ResponsablePhone}
              name="ResponsablePhone"
              onChange={handleInputChange}
            />
          </div>
        )}
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Echéance 1
          </label>
          <input
            type="number"
            id=""
            placeholder="Echéance 1"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            disabled={clienFormAction === "show"}
            value={clientFormData?.Deadline1}
            name="Deadline1"
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Echéance 2
          </label>
          <input
            type="number"
            id=""
            placeholder="Echéance 2"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            disabled={clienFormAction === "show"}
            value={clientFormData?.Deadline2}
            name="Deadline2"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Plafond 1
          </label>
          <input
            type="number"
            id=""
            placeholder="Plafond 1"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            disabled={clienFormAction === "show"}
            value={clientFormData?.Plaphon1}
            name="Plaphon1"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Plafond 2
          </label>
          <input
            type="number"
            id=""
            placeholder="Plafond 2"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            disabled={clienFormAction === "show"}
            value={clientFormData?.Plaphon2}
            name="Plaphon2"
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Etat de client (Débit)
          </label>
          <input
            type="number"
            id=""
            placeholder="Etat de client (Débit)"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            disabled={clienFormAction === "show"}
            value={clientFormData?.ClientSumOffFPrice}
            name="ClientSumOffFPrice"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Etat de client (Crédit)
          </label>
          <input
            type="number"
            id=""
            placeholder="Etat de client (Crédit)"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            disabled={clienFormAction === "show"}
            value={clientFormData?.ClientReleveCredit}
            name="ClientReleveCredit"
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Etat de client (actuel)
          </label>
          <input
            type="number"
            id=""
            placeholder="Etat de client (actuel)"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            disabled={clienFormAction === "show"}
            value={clientFormData?.ClientSumOffFPriceCurrent}
            name="ClientSumOffFPriceCurrent"
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Code comptable
          </label>
          <input
            type="text"
            id=""
            placeholder="Code comptable"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            disabled={clienFormAction === "show"}
            value={clientFormData?.CodeComptable}
            name="CodeComptable"
            onChange={handleInputChange}
          />
        </div>
      </div>

      {clienFormAction === "edite" && (
        <div className="mt-5">
          <Button
            variant="gradient"
            color="#236571"
            onClick={() => handleUpdateClient()}
            className="mr-1"
          >
            <span>Modifier</span>
          </Button>
          <Button
            variant="text"
            color="#cf503f"
            onClick={() => handleCancelOp()}
            className="mr-1"
          >
            <span>Annuler</span>
          </Button>
        </div>
      )}

      {clienFormAction === "add" && (
        <div className="mt-5">
          <Button
            variant="gradient"
            color="#236571"
            onClick={() => handleAddNewClient()}
            className="mr-1"
          >
            <span>Ajouter</span>
          </Button>
          <Button
            variant="text"
            color="#cf503f"
            onClick={() => handleCancelOp()}
            className="mr-1"
          >
            <span>Annuler</span>
          </Button>
        </div>
      )}
    </form>
  );
}

export default ClientForm;
