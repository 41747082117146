import React, { useState } from "react";
import { Button } from "@material-tailwind/react";
import { addNewCategoryApi } from "api/category";

function NewCategory({ closePopup }) {
  const [categoryName, setCategoryName] = useState('')

  const handleAddCategory = async () => {
    try {
      await addNewCategoryApi(categoryName)
      closePopup("Reload");
    }
    catch (err) {
      console.log(err)
    }
  };
  const handleCancelOp = () => {
    closePopup("Close");
  };

  return (
    <form>
      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Nom du catégory
          </label>
          <input
            type="text"
            id=""
            placeholder="Nom du catégory"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            onChange={e => setCategoryName(e.target.value)}
          />
        </div>
      </div>

      <div className="mt-5">
        <Button
          variant="gradient"
          color="#236571"
          onClick={() => handleAddCategory()}
          className="mr-1"
        >
          <span>Ajouter</span>
        </Button>
        <Button
          variant="text"
          color="#cf503f"
          onClick={() => handleCancelOp()}
          className="mr-1"
        >
          <span>Annuler</span>
        </Button>
      </div>
    </form>
  );
}

export default NewCategory;
